<template>
  <div class="wrapper"
       v-if="JSON.stringify(details)!='{}'">
    <img class="order-img"
         src="/img/order_top.png"
         alt="">
    <!--收货地址-->
    <div class="user-address-box flex ai-c jc-sb ai-c">
      <div class="address-detail">
        <div class="user-basic-info flex ai-c jc-sb">
          <div class="user-name">{{details.receiverName}}</div>
          <div class="user-phone">{{details.receiverPhone}}</div>
        </div>
        <div class="user-address flex-box">
          <div class="address lines">
            {{details.address}}
          </div>
        </div>
      </div>
    </div>
    <!--订单列表-->
    <div class="goods-item">
      <div class="goods-shop">
        <img src="/img/icon-shop.png"
             alt="">
        i团团云供应链
      </div>
      <div class="list"
           v-if="details.goodsList">
        <div class="goods flex"
             v-for="(item, index) in details.goodsList"
             @click="$router.push({path: '/home/details',query:{id: item.goodsId}})"
             :key="index">
          <img :src="item.goodsPic?item.goodsPic.split(',')[0]:''"
               alt="">
          <div class=""
               style="width: calc(100% - 1.84rem)">
            <div class="flex ai-bl jc-sb">
              <div class="name line">{{item.goodsName}}</div>
              <div class="price">
                ¥{{item.goodsPrice}}
              </div>
            </div>
            <div class="flex ai-c jc-sb"
                 style="margin-top:.1rem">
              <div class="classification line">{{item.specifications}}</div>
              <div class="nb">x{{item.goodsQuantity}}</div>
            </div>
            <div class="refund flex jc-fd"
                 v-if="details.status==1||details.status==2||details.status==3">
              <div class="refund-btn"
                   v-if="item.refundStatus==0&&details.status!=3"
                   @click.stop="refundClick(item)">退款</div>
              <div v-if="item.refundStatus==1"
                   @click.stop="refundClick(item)"
                   class="refund-btn">售后中</div>
              <div v-if="item.refundStatus==2"
                   @click.stop="refundClick(item)"
                   class="refund-btn">待退货</div>
              <div v-if="item.refundStatus==3"
                   @click.stop="refundClick(item)"
                   class="refund-btn">待退款</div>
              <div v-if="item.refundStatus==4"
                   @click.stop="refundClick(item)"
                   class="refund-btn">已退款</div>
              <div v-if="item.refundStatus==5"
                   @click.stop="refundClick(item)"
                   class="refund-btn">售后失败</div>
            </div>
            <!-- <div class="fw">7天无理由退换</div> -->
          </div>
        </div>
      </div>
      <div class="picker-box flex ai-c jc-sb"
           v-if="details.couponAmount>0">
        <div class="picker-name flex ai-c"><img src="/img/icon-yhq.png"
               alt=""> 优惠券</div>
        <div class="flex ai-c">
          <div class="txt"
               style="color:#F35E3A">-￥{{details.couponAmount}}</div>
        </div>
      </div>
      <div class="subtotal flex ai-c jc-fd">
        <div class="subtotal-zl">共{{details.quantity}}件商品</div>
        <div class="subtotal-lb">小计：</div>
        <div class="subtotal-price">¥{{details.payAmount}}</div>
      </div>
    </div>
    <div class="information copyTxt"
         @click="copy"
         :data-clipboard-text="details.orderSn">
      <div class="title">订单信息</div>
      <div class="txt flex ai-c jc-sb">订单编号：{{details.orderSn}}<div class="b">复制</div>
      </div>
      <div class="txt">订单时间：{{details.createTime}}</div>
      <div class="txt"
           v-if="details.paymentTime">支付时间：{{details.paymentTime}}</div>
      <div class="txt"
           v-if="details.deliveryTime">发货时间：{{details.deliveryTime}}</div>
      <div class="txt"
           v-if="details.receiveTime">成交时间：{{details.receiveTime}}</div>
      <div class="txt"
           v-if="details.note">订单备注：{{details.note}}</div>
    </div>
    <v-loveGoodsList />
    <div class="kong"></div>
    <div class="btn-box flex ai-c jc-sb">
      <div class="go-home flex fd-c ai-c jc-c"
           @click="$router.push('/home')">
        <img src="/img/icon-home.png"
             alt="">
        商城
      </div>
      <div class="flex"
           v-if="details.status==0">
        <div class="btn"
             @click.stop="cancelClick(details.orderSn)">取消订单</div>
        <div class="btn"
             @click.stop="payOrder(details.orderSn)">确认付款</div>
      </div>
      <div class="flex"
           v-if="details.status==3">
        <div class="btn"
              style="border: 0.02rem solid #61626a;color: #61626a;"
             @click.stop="$router.push({path:'/my/orderListSMCS/orderDetails/logistics',query: {id: details.orderSn}})">查看物流</div>
      </div>
      <div class="flex"
           v-if="details.status==2">
        <div class="btn"
             @click.stop="$router.push({path:'/my/orderListSMCS/orderDetails/logistics',query: {id: details.orderSn}})">查看物流</div>
        <div class="btn"
              v-show="!details.isRefund"
             @click.stop="confirm(details.orderSn)">确认收货</div>
      </div>
    </div>
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
import { orderDetails, cancelOrder, unifiedOrder, confirmOrder,orderPay } from '../../../../api'
export default {
  components: {
    'v-loveGoodsList': loveGoodsList
  },
  props: {},
  data() {
    return {
      changeAddress: {},
      defaultAddress: true,
      details: {}
    };
  },
  watch: {},
  computed: {},
  methods: {
    //手机号加星
    plusXing(str, frontLen, endLen) {
      str = String(str)
      var len = str.length - frontLen - endLen;
      var xing = '';
      for (var i = 0; i < len; i++) {
        xing += '*';
      }
      return str.substr(0, frontLen) + xing + str.substr(str.length - endLen);
    },
    copy() {
      var clipboard = new this.Clipboard(".copyTxt");
      clipboard.on("success", (e) => {
        // 释放内存
        this.$toast.success("复制成功!");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$dialog
          .alert({
            message: "该浏览器不支持自动复制",
          })
          .then(() => {
            // on close
          });
        // 释放内存
        clipboard.destroy();
      });
    },
    orderDetails() {
      orderDetails(
        this.$route.query.id
      ).then(res => {
        var data = res.data.data
        data.quantity = 0
        data.receiverPhone = this.plusXing(data.receiverPhone, 3, 4)
        data.address = data.receiverProvince + (data.receiverProvince == data.receiverCity ? '' : data.receiverCity) + data.receiverRegion + data.receiverDetailAddress
        data.isRefund = false
        for (var im of data.goodsList) {
          //判断是否属于售后单
          if (im.refundStatus!=0&&im.refundStatus!=4&&im.refundStatus!=5) {
            data.isRefund = true
          }
          data.quantity += im.goodsQuantity
          var specifications = []
          im.goodsAttr = JSON.parse(im.goodsAttr)
          for (var val of im.goodsAttr) {
            specifications.push(val.v)
          }
          im.specifications = specifications.join('、')
        }
        this.details = data
        console.log(res.data);
      })
    },
    //确认收货
    confirm(orderSn) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认收货',
      })
        .then(() => {
          // on confirm
          this.$toast.loading({
            duration: 0,
            forbidClick: true
          })
          confirmOrder(orderSn).then(res => {
            this.$toast.success({
              message: '确认收货成功',
              duration: 1500,
              forbidClick: true
            })
            this.orderDetails()
          }).catch(() => {
            this.$toast.clear()
          })
        })
    },
    //获取支付订单号
    payOrder(data) {
      unifiedOrder(data).then(res => {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          console.log('在微信端');
          this.wxPay(res.data.data,data)
        } else if (ua.match(/xmsmk/i) == "xmsmk"){
            this.pay(res.data.data,data)
        } else {
            this.ixAppPay(res.data.data,data)
        }
      }).catch(res => {
        this.$toast.clear()
      })
    },
    //i厦门app支付
    ixAppPay (orderNo,orderSn) {
        ixm.openPay((res)=>{
            // 成功回调
            if (res.result){
                this.$toast.success({
                    message: '支付成功',
                    duration: 1500,
                    forbidClick: true,
                })
                this.orderDetails()
            } else {
                this.$toast.clear()
                this.orderDetails()
            }
        }, {
            orderId: orderNo,  // 订单标识
            thirdSystemId: localStorage.getItem("payThirdSystemId"), // 第三方应用标识
        })
    },
    //微信支付
    wxPay(orderNo,orderSn) {
        location.href='https://smk.ixiamen.org.cn/smk/smk-unipay-transfer/#/?orderNo='+orderNo
    },
    //厦门市民卡app支付
    pay(orderNo,orderSn) {
      var that = this
      var ua = navigator.userAgent
      var preloadJs = function () {
        // Promise语法是ES6⽀持的，其他不⽀持的请⾃⾏转成ES5
        return new Promise((resolve, rejct) => {
          if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
            if (window.UmsApi === undefined) {
              document.addEventListener('OnUmsApiReady',
                function () {
                  resolve(true)
                }, false)
            } else {
              resolve(true)
            }
          } else {
            // ⾮市⺠卡接⼊
            reject('⾮市⺠卡接⼊')
          }
        })
      }
      function unifyPay(orderNumber) {
        UmsApi.page.processUnifyPay({ orderNo: orderNumber }, function (data) {
          // data中respCode为：0000 表示成功，其他情况失败
          console.log("返回数据:" + JSON.stringify(data))
          that.orderPay(data.errCode,orderSn)
        }, function (data) {
          //失败
          console.log("返回数据:" + JSON.stringify(data))
        });
      }
      preloadJs().then(res => {
        console.log(res)
        // 调⽤市⺠卡相关⽅法
        that.$toast.clear()
        unifyPay(orderNo)
      })
    },
    orderPay(code,orderSn) {
        orderPay(orderSn).finally(()=>{
            if (code=='0000') {
                this.$toast.success({
                    message: '支付成功',
                    duration: 1500,
                    forbidClick: true,
                })
                this.orderDetails()
            } else {
                this.$toast.clear()
                this.orderDetails()
            }
      })
    },
    //取消订单
    cancelClick(orderSn) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认取消该订单',
      })
        .then(() => {
          // on confirm
          this.$toast.loading({
            duration: 0,
            forbidClick: true
          })
          cancelOrder(orderSn).then(res => {
            this.$toast.success({
              message: '取消订单成功',
              duration: 1500,
              forbidClick: true
            })
            this.orderDetails()


          }).catch(() => {
            this.$toast.clear()
          })
        })
    },
    //售后点击
    refundClick(item) {
      console.log(item);
      if ((item.refundStatus == 0 || item.refundStatus == 5)&&this.details.status!=3) {
        this.$router.push({ path: '/my/orderListSMCS/refund', query: { id: this.details.orderSn, orderItemId: item.orderItemId, orderStatus: this.details.status } })
      } else {
        this.$router.push({ path: '/my/orderListSMCS/refundDetails/details', query: { id: this.details.orderSn, orderItemId: item.orderItemId } })
      }
    }
  },
  created() {
    this.orderDetails()
  },
  mounted() { },
};
</script>
<style scoped>
/* .wrapper {
} */
.order-img {
  width: 100%;
  display: block;
}
.user-address-box {
  width: 100%;
  padding: 0.2rem 0.1rem 0.2rem 0.3rem;
  background: #fff;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
}
.no-address {
  width: 100%;
  margin: 0.3rem;
  margin-left: 0;
  position: relative;
  font-size: 0.32rem;
  font-weight: bold;
}
.address-detail {
  width: 6.5rem;
}
.user-basic-info {
  width: 100%;
  margin-bottom: 0.15rem;
}
.user-name,
.icon-box {
  font-size: 0.32rem;
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-phone,
.address {
  font-size: 0.32rem;
  width: 65%;
}
.address {
  width: 90%;
}
.default {
  padding: 0 0.1rem;
  color: #d54848;
  font-size: 0.22rem;
  border: 1px solid #e58a8a;
  border-radius: 0.08rem;
}
.address {
  font-size: 0.26rem;
}
.goods-item {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  margin-top: 0.2rem;
  padding-bottom: 0.4rem;
}
.goods-item .goods-shop {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: bold;
  color: #61626a;
  margin-left: 0.3rem;
  margin-top: 0.3rem;
}
.goods-item .goods-shop img {
  width: 0.22rem;
  height: 0.2rem;
  margin-right: 0.1rem;
}
.list {
  margin-top: 0.1rem;
}
.list .goods {
  width: 100%;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem;
}
.list .goods img {
  width: 1.54rem;
  height: 1.54rem;
  margin-right: 0.3rem;
  object-fit: cover;
}
.list .goods .name {
  /* width: auto; */
  /* width: 3.9rem; */
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-right: 0.16rem;
}
.list .goods .price {
  white-space: nowrap;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.list .goods .price font {
  font-size: 0.24rem;
}
.list .goods .classification {
  max-width: 3.5rem;
  height: 0.38rem;
  padding: 0 0.05rem;
  line-height: 0.38rem;
  box-sizing: border-box;
  background: #f4f4f4;
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}
.list .goods .nb {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}
.list .goods .fw {
  width: fit-content;
  height: 0.28rem;
  line-height: 0.28rem;
  padding: 0 0.05rem;
  background: #f9efed;
  font-size: 0.2rem;
  font-family: PingFang;
  font-weight: 500;
  color: #f6593f;
  margin-top: 0.4rem;
}

.subtotal {
  /* margin-top: .5rem; */
}
.subtotal-zl {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}
.subtotal-lb {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-left: 0.15rem;
}
.subtotal-price {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 800;
  color: #f35e3a;
  margin-right: 0.38rem;
}
.subtotal-price font {
  font-size: 0.28rem;
}
.kong {
  width: 100%;
  height: 2.6rem;
}
.btn-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.4rem;
  background-color: #fff;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.btn-box .go-home {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-left: 0.36rem;
}
.btn-box .go-home img {
  width: 0.36rem;
  height: 0.36rem;
}
.btn-box .btn {
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background: #f35e3a;
  border-radius: 0.4rem;
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  margin-right: 0.3rem;
}
.btn-box .btn {
  width: 1.7rem;
  height: 0.7rem;
  line-height: 0.7rem;
  text-align: center;
  background: #fdfeff;
  border: 0.02rem solid #61626a;
  border-radius: 0.35rem;
  margin-right: 0.27rem;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.btn-box .btn:nth-last-child(1) {
  color: #f35e3a;
  border-color: #f35e3a;
}
.information {
  margin-top: 0.2rem;
  width: 100%;
  padding: 0.4rem 0.3rem 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
}
.information .title {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #282828;
}
.information .txt {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-top: 0.22rem;
}
.information .txt .b {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #f35e3a;
}
.picker-box {
  width: 100%;
  padding: 0.24rem 0.2rem 0.24rem 0.3rem;
  box-sizing: border-box;
}
.picker-box .picker-name {
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.picker-box .picker-name img {
  width: 0.26rem;
  height: 0.35rem;
  margin-right: 0.1rem;
}
.picker-box .txt {
  width: 5rem;
  text-align: right;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-right: 0.1rem;
}
.refund {
  margin-top: 0.6rem;
}
.refund-btn {
  width: 1.26rem;
  text-align: center;
  line-height: 0.56rem;
  height: 0.56rem;
  background: #ffffff;
  border: 0.02rem solid #61626a;
  border-radius: 0.28rem;
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
</style>